import React from "react"

import SergioPicture from "../assets/team-members/sergio.jpg"
import CesarPicture from "../assets/team-members/cesar.jpg"
import HugoPicture from "../assets/team-members/hugo.jpg"
import ValeriaPicture from "../assets/team-members/valeria.png"
import KarlaPicture from "../assets/team-members/karla.png"
import TonyPicture from "../assets/team-members/tony.jpg"
import DanielPicture from "../assets/team-members/sergio.jpg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../styles/social-media.scss"
import "../styles/meiquer-team.scss"

const teamContent = [
  {
    name: "Sergio Hernández",
    picture: SergioPicture,
    role: "Educación y Juego",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin: "https://www.linkedin.com/in/sergiohernandezv",
  },
  {
    name: "César Espinosa",
    picture: CesarPicture,
    role: "Dirección General",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin: "https://www.linkedin.com/in/eldelcesar",
  },
  {
    name: "Hugo Michel",
    picture: HugoPicture,
    role: "Diseño de Producto",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin: "https://www.linkedin.com/in/hugo-m-964a5b82",
  },
  {
    name: "Valeria Campos",
    picture: ValeriaPicture,
    role: "Transversalidad",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin: "https://www.linkedin.com/in/valeria-campos-2a0bb416b",
  },
  {
    name: "Miguel Pérez",
    picture: SergioPicture,
    role: "Tecnología",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin: "https://www.linkedin.com/in/miguel-pérez-garc%C3%ADa-73b760b9",
  },
  {
    name: "Sebastián de la Hoz",
    picture: SergioPicture,
    role: "Consultor de Juego",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin: "https://www.linkedin.com/in/sdelahoz",
  },
  {
    name: "Karla Nuñez",
    picture: KarlaPicture,
    role: "Consultor de Juego",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
  },
  {
    name: "Tony Navarrete",
    picture: TonyPicture,
    role: "Misiones",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
  },
  {
    name: "Daniel Blanco",
    picture: DanielPicture,
    role: "Misiones",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
  },
  {
    name: "Diego de Dios",
    picture: SergioPicture,
    role: "Escuelas",
    description:
      "Le gusta jugar fútbol, crear máquinas de cartón con sus hijos en los fines.",
    linkedin:
      "https://www.linkedin.com/in/diego-mart%C3%ADnez-de-dios-673930138",
  },
]

const teamContentTitle = (
  <h2 className="w-normal">
    ¿<span className="s-italic">Quiénes</span>{" "}
    <span className="c-turquoise w-bold">juegan</span> para{" "}
    <span className="w-bold">transformar</span>?
  </h2>
)

export default () => (
  <section className="meiquer-team ">
    <div className="section-title">{teamContentTitle}</div>
    <div className="team-members">
      {teamContent.map((member, idx) => (
        <div className="member has-text-centered" key={idx}>
          <div>
            <img
              style={{ width: "8em" }}
              src={member.picture}
              alt="Fonnor Logo"
            />
            <h5>{member.name}</h5>
            <p
              style={{ letterSpacing: ".3rem" }}
              className="c-turquoise w-bold"
            >
              {member.role}
            </p>
          </div>
          <div>
            <p>{member.description}</p>
            {member.linkedin ? (
              <a
                className="social-link"
                target="_blank"
                rel="noopener noreferrer"
                href={member.linkedin}
              >
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </a>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  </section>
)
