import React from "react"

import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"

import Button from "../../components/button"
import BiContent from "../../components/bi-content"
import MainBanner from "../../components/main-banner"
import ConceptSection from "../../components/concept-section"

import IdeaIcon from "../../assets/icons/icons-1x/idea.svg"
import RocketIcon from "../../assets/icons/icons-1x/blue-rocket@2x.png"
import VideogameIcon from "../../assets/icons/icons-1x/blue-videogame@2x.svg"
import DinosaurIcon from "../../assets/icons/icons-1x/dinosaur.svg"
import ClockIcon from "../../assets/icons/icons-1x/clock.svg"
import SpaceShipIcon from "../../assets/icons/icons-1x/blue-spaceship@2x.svg"
import PlaceholderImage from "../../assets/placeholders/placeholder-01@2x.png"
import ReporteImpacto from "../../assets/files/Estacion Meiquer – Reporte de Impacto 2019.pdf"
import MeiquerTeam from "../../components/meiquer-team"

const infoBannerContent = (
  <>
    <div>
      <h1>Inspiramos,</h1>
      <h1>imaginamos,</h1>
      <h1>creamos,</h1>
      <h1 className="c-turquoise">jugamos,</h1>
      <h1>compartimos</h1>
      <h1 className="w-normal s-italic">...y repetimos</h1>
    </div>
  </>
)

const impactBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="s-italic">Empoderar</span> agentes de cambio con{" "}
      <span className="c-turquoise w-bold">juego</span>{" "}
      <span className="w-bold">creativo</span>
    </h2>
    <p>
      Es por esto que acercamos el{" "}
      <span className="w-bold">juego creativo</span> diseñando servicios,
      productos y contenido para las niñas y los niños, y{" "}
      <span className="w-bold">crear comunidades</span> donde el juego sea
      seguro, sostenible, imaginativo y libre para todas y todos.
    </p>
    <div className="columns">
      <div className="column is-two-thirds pl-0">
        <Button href={ReporteImpacto} download>
          Modelo de Impacto
        </Button>
      </div>
    </div>
  </>
)

const reasons = [
  {
    top: (
      <img
        style={{ height: "10rem" }}
        src={DinosaurIcon}
        alt="Ícono Dinosaurio"
      />
    ),
    content: (
      <>
        <h3>Mucha Inseguridad</h3>
        <p>
          60% de los niños y las niñas han experimentado por lo menos un caso de
          violencia física o psicológica de por lo menos 1 familiar.
        </p>
        <span className="reference">UNICEF</span>
      </>
    ),
  },
  {
    top: <img style={{ height: "10rem" }} src={ClockIcon} alt="Ícono Reloj" />,
    content: (
      <>
        <h3>Poco Tiempo</h3>
        <p>
          México es el país con más horas laboradas en la OCDE (8 horas más que
          el promedio) y donde el 98% de las mujeres cumplen con doble jornada
          laboral.
        </p>
        <span className="reference">INEGI</span>
      </>
    ),
  },
  {
    top: (
      <img
        style={{ height: "10rem" }}
        src={SpaceShipIcon}
        alt="Ícono Nave Espacial"
      />
    ),
    content: (
      <>
        <h3>Falta de Espacios</h3>
        <p>
          El 75% de las personas en México consideran la vía pública peligrosa,
          y 60% de las mujeres se sienten inseguras en parques o centros
          recreativos.
        </p>
        <span className="reference">Forbes/ONU Mujeres</span>
      </>
    ),
  },
]

const reasonsTitle = (
  <h2 className="w-normal text-right">
    Pero, ¿<span className="s-italic">Por qué</span>{" "}
    <span className="c-turquoise w-bold">jugamos</span>
    {""}
    <span className="w-bold">menos</span>?
  </h2>
)

const solutions = [
  {
    top: <img style={{ height: "10rem" }} src={IdeaIcon} alt="Ícono Idea" />,
    content: (
      <>
        <h3>Áreas de Trabajo</h3>
        <p>
          Desarrollamos proyectos alrededor de cinco Áreas de Trabajo que se
          complementan entre sí.
        </p>
      </>
    ),
    bottom: <Button linkTo="/nosotros/areas-trabajo">Aprender Más</Button>,
  },
  {
    top: (
      <img style={{ height: "10rem" }} src={RocketIcon} alt="Ícono Cohete" />
    ),
    content: (
      <>
        <h3>Modelo de Impacto</h3>
        <p>
          Diseñamos un modelo de impacto con cuatro ejes principales: Juego,
          Comunidad, Ambiente y Aprendizaje.
        </p>
      </>
    ),
    bottom: (
      <Button href={ReporteImpacto} download>
        Leer Más
      </Button>
    ),
  },
  {
    top: (
      <img
        style={{ height: "10rem" }}
        src={VideogameIcon}
        alt="Ícono Videojuego"
      />
    ),
    content: (
      <>
        <h3>Reporte Anual</h3>
        <p>
          Generamos un reporte de impacto para ser transparentes con nuestras
          comunidades, aliados y clientes.
        </p>
      </>
    ),
    bottom: (
      <Button href={ReporteImpacto} download>
        Descargar
      </Button>
    ),
  },
]

const solutionsTitle = (
  <h2 className="w-normal text-right">
    ¿Cómo <span className="c-turquoise w-bold">jugamos</span>{" "}
    <span className="s-italic">para</span>{" "}
    <span className="w-bold">transformar</span>?
  </h2>
)

//New
const teamBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Organización</span> y{" "}
      <span className="c-turquoise w-bold">equipo</span>
    </h2>
    <p>
      Estación Meiquer comenzó con la iniciativa Sergio Hernández, profesor y
      papá, quien vio en el juego una forma de cambiar las cosas.
    </p>
    <p>
      En 2020, somos un equipo de diez agentes de cambio que creen en la
      importancia de jugar, y que, por supuesto, ¡juegan mucho!
    </p>
    <p>
      El equipo Meiquer cuenta con mucho talento para transformar comunidades
      jugando.
    </p>
  </>
)

const NosotrosPage = props => {
  const data = useStaticQuery(graphql`
    query NosotrosQuery {
      nosotrosImg: file(relativePath: { eq: "meiquer-nosotros-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jocotanImg: file(relativePath: { eq: "meiquer-jocotan-03.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meiquerOrg5Img: file(relativePath: { eq: "meiquer-org-05.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Nosotros" />

      <MainBanner
        content={infoBannerContent}
        image={data.nosotrosImg.childImageSharp.fluid}
      />
      <ConceptSection concepts={reasons} title={reasonsTitle} />
      <MainBanner
        image={data.jocotanImg.childImageSharp.fluid}
        content={impactBannerContent}
        fromBottom={false}
      />
      <ConceptSection concepts={solutions} title={solutionsTitle} />
      <MainBanner
        image={data.meiquerOrg5Img.childImageSharp.fluid}
        content={teamBannerContent}
        fromBottom={false}
        anchor="organizacion"
      />
      <MeiquerTeam />
    </Layout>
  )
}

export default NosotrosPage
